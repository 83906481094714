<template>
  <div class="home-body-container">
    <div class="middle-container">
      <div class="introduce-bg-container"  >
          <img src="@/assets/introduce-bg.png" alt="">
      </div>
    </div>
    <div class="bottom-container">
        <img src="@/assets/bottom-background.jpg" alt="">
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
    };
  },
};
</script>
<style lang="scss">
.home-body-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  .middle-container {
    display: flex;
    justify-content: center;
    background: #ffffff;
    .introduce-bg-container {
      width: 70%;
      min-width: 800px;
      img{
          width: 100%;
      }
    }
  }
  .bottom-container{
      img{
          width: 100%;
      }
  }
}
</style>